import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import BackdropLoading from "../../components/BackdropLoading";
import MuiAlert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import parseErrorCode from "../../utils/parse-error-code";
import Snackbar from "@mui/material/Snackbar";
import { useSelector, useDispatch } from "react-redux";
import { loginThunk } from "../../redux/slices/authSlice";
import { resetProfileAction } from "../../redux/slices/profileSlice";
import { resetEducationAction } from "../../redux/slices/educationSlice";
import { resetCertificationAction } from "../../redux/slices/certificationSlice";
import { resetExperienceAction } from "../../redux/slices/experienceSlice";
import { resetProjectAction } from "../../redux/slices/projectSlice";
import { resetSkillSetAction } from "../../redux/slices/skillSetSlice";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      © {new Date().getFullYear()} Shahzaib Minhas, Inc
    </Typography>
  );
}

const theme = createTheme();

export default function LoginPage() {
  const navigate = useNavigate();
  const [snackAlert, setSnackAlert] = useState(null);

  const { isLoading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = new FormData(event.currentTarget);
      const email = data.get("email");
      const password = data.get("password");
      await dispatch(loginThunk(email, password));
      
      dispatch(resetProfileAction());
      dispatch(resetEducationAction());
      dispatch(resetCertificationAction());
      dispatch(resetExperienceAction());
      dispatch(resetProjectAction());
      dispatch(resetSkillSetAction());

      navigate("/", { replace: true });
    } catch (error) {
      const errorMessage = parseErrorCode(error.code);
      setSnackAlert({
        severity: "error",
        title: "Error",
        message: errorMessage,
      });
    }
  };

  const closeSnackAlert = () => {
    setSnackAlert(null);
  };

  const snackbar = () => (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      severity={snackAlert?.severity}
      autoHideDuration={6000}
      open={snackAlert ? true : false}
      onClose={closeSnackAlert}
    >
      <Alert
        onClose={closeSnackAlert}
        severity={snackAlert?.severity}
        sx={{ width: "100%" }}
      >
        {snackAlert?.title && <AlertTitle>{snackAlert?.title}</AlertTitle>}
        {snackAlert?.message}
      </Alert>
    </Snackbar>
  );

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <BackdropLoading isLoading={isLoading} />
        {snackbar()}
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
